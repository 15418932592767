import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layout/layout_features.js";
import { Link } from 'gatsby';
import { Image } from "../../components";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h4>{`Filter content`}</h4>
    <p>{`Click on the filter icon at the very left of the search box to open the
filter options.`}</p>
    <Image src="features/filter.png" className="w-2/3 my-12 mx-auto" mdxType="Image" />
    <p>{`You can select the activity type, indicated with an activity name and
the number of these activities in the database.`}</p>
    <br />
    <p>{`The available filter options will adapt to the currently selected activity.`}</p>
    <br />
    <p>{`Also filter by difficulty. By default all difficulties are shown.
Click on one to unselect it.`}</p>
    <br />
    <p>{`Other filter options are type of access, user reviews (popularity), or
condition reports.`}</p>
    <br />
    <br />
    <Link className="float-left" to="/features/search" mdxType="Link">
  ← Search
    </Link>
    <Link className="float-right" to="/features/activitypage" mdxType="Link">
  Activity page →
    </Link>
    <br className="clear-both" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      